import {BFormCheckbox, BFormFile, BFormGroup, BFormInput, BModal, BTable} from "bootstrap-vue";
import configProvider from "@@/core/configProvider";

export default {
  components: {
    BFormGroup, BFormCheckbox, BFormInput, BFormFile, BTable, BModal
  },
  configProvider,
  props: {
    shipping: Object,
    mode: {
      default: 'create',
      type: String
    },
    url: String,
    value: Number
  },
  data() {
    return {
      disabled: false,
      publicItem: this.value,
      fields: [{
        key: 'name',
        label: 'استان'
      },
        {
          key: 'price',
          label: 'هزینه ارسال (تومان)'
        },
      ],
      customerRoleFields: [{
        key: 'name',
        label: 'نقش'
      },
        {
          key: 'amount',
          label: 'هزینه ارسال (تومان)'
        },
      ],
      provinces: [],
      customerRoles: []
    }
  },
  watch: {
    value(newVal) {
      this.publicItem = newVal
    },
    publicItem(newVal) {
      this.$emit('input', newVal)
    },
  },
  mounted() {
    this.loadInitData()
  },
  methods: {
    async loadInitData() {
      try {
        const response = await this.$axios.get('admin/widgets?all=1', {
          params: {
            widgets: '["customer_roles", "provinces"]'
          }
        })
        this.provinces = response.data.data.widgets.provinces.map(item => {
          item.pivot = {
            price: 1000
          };
          return item
        })

        if (response.data.data.widgets.customer_roles) {
          this.customerRoles = response.data.data.widgets.customer_roles.map(item => {
            item.pivot = {
              amount: 0
            };
            return item
          })
        }

      } catch (error) {
        this.$root.notify(error)
      }
    },
    async submit(e) {
      e.preventDefault()
      this.disabled = true
      try {
        const formData = new FormData(this.$refs.form)
        formData.append('more_packet_price', this.shipping.more_packet_price)
        formData.append('default_price', this.shipping.default_price)
        formData.append('free_threshold', this.shipping.free_threshold != null ? this.shipping
            .free_threshold : '')
        formData.append('_method', this.mode == 'edit' ? 'PUT' : 'post')
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response.data.message, 'success')
        if (this.mode == 'edit') {
          this.$root.$set(this.$parent._data.shippings, this.shipping.index, response.data.data
              .shipping);
        } else {
          this.$parent._data.shippings.unshift(response.data.data.shipping)
        }
        this.$root.$emit('bv::hide::modal', 'shipping-modal')
        this.provinces = this.provinces.map(item => {
          item.pivot = {
            price: 1000
          };
          return item
        })
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
  }
}
