/**
 * @property {{id: number, title: string, short_description: string, description: string, unit_price: number,
 * purchase_price: number, discount_type: string, discount: number, SKU: string, barcode: string, brand_id: number,
 * unit_id: number, meta_description: string, meta_title: string, low_stock_quantity_warning: number,
 * show_quantity: boolean, chargeable: boolean, status: string, approved_at: string, images: array, price: number, tags: array,size_charts: array,
 * varieties: [{
 *   id: number,
 *   price: number,
 *   SKU: string,
 *   barcode: string
 *   purchase_price: number,
 *   product_id: number,
 *   discount_type: string,
 *   discount: number,
 *   quantity: number,
 *   color_id: number,
 *   images: array,
 *   attributes: array,
 *  is_head: number
 * }],
 * categories: [
 *   {
 *     id: number,
 *   }
 * ],
 * specifications: [],
 *
 * }} edit
 */
export default class productRequest {
  constructor($data, productVarietiesComponent) {
    this.edit = $data.edit
    this.productInformation = $data.productInformation
    this.productPricing = $data.productPricing
    this.productDescription = $data.productDescription
    this.productImages = $data.productImages
    this.productSizeCharts = $data.productSizeCharts
    this.selectedSpecs = $data.selectedSpecs
    this.productVarieties = $data.productVarieties // currently useless
    this.productVarietiesComponent = productVarietiesComponent;
    this.productPublishing = $data.productPublishing
    this.productShow = $data.productShow
    this.productSeo = $data.productSeo
    this.productOther = $data.productOther
    this.productGifts = $data.productGifts
  }

  generateRequestData() {
    let data = {
      title: this.productInformation.title,
      short_description: this.productDescription.shortDescription,
      description: this.productDescription.description,
      unit_price: this.productPricing.unitPrice,
      purchase_price: this.productPricing.purchasePrice,
      discount_type: this.getDiscountType(this.productPricing.discountType),
      discount: this.getDiscountType(this.productPricing.discountType) ? this.productPricing.discount : null,
      discount_until: this.productPricing.discountUntil,
      SKU: this.productInformation.sku,
      weight: this.productInformation.weight,
      max_limit: this.productInformation.max_limit,
      barcode: this.productInformation.barcode ? this.productInformation.barcode + '' : '',
      categories: this.productInformation.categories,
      quantity: this.getProductInformationQuantity(this.productInformation),
      unit_id: this.productInformation.unit,
      brand_id: this.productInformation.brand,
      tags: this.productInformation.tags,
      status: this.productPublishing.status,
      show_quantity: !!this.productShow.showQuantity,
      chargeable: !!this.productShow.chargeable,
      new_product_in_home: !!this.productShow.new_product_in_home,
      low_stock_quantity_warning: !!this.productOther.lowStockQuantityWarning,
      images: this.getImages(this.productImages),
      varieties: this.getVarieties(this.productVarieties, this.productVarietiesComponent),
      specifications: this.getSpecifications(this.selectedSpecs),
      size_charts: this.getSizeCharts(this.productSizeCharts),
      meta_title: this.productSeo.metaTitle,
      meta_description: this.productSeo.metaDescription,
      slug: this.productSeo.slug,
      listen_charge: this.productOther.listenCharge,
      gifts: this.getProductGifts(this.productGifts),
      no_store_update: Boolean(this.edit),
    }

    // حروف اضافه گذاشته میشه در بعضی مواقع علتش مشخص نیست
    this.productPublishing.publishedAt = this.productPublishing.publishedAt.replaceAll('j', '')
    if (this.productPublishing.scheduling && this.productPublishing.publishedAt) {
      data.published_at = window.moment(this.productPublishing.publishedAt, 'YYYY/MM/DD HH:mm').unix()
    } else {
      data.published_at = window.moment().unix()
    }


    return {
      product: data
    }
  }
  getProductInformationQuantity(productInformation) {
    return productInformation.quantity
  }

  // فعلا به پارامتر اول نیازی نیست
  getVarieties(productVarieties, productVarietiesComponent) {
    let sortedVarieties = productVarietiesComponent.getSortedVarieties();
    const copyVarieties = sortedVarieties.filter(v => {
      return v.present && (v.data.quantity === 0 || !!v.data.quantity);
    })
    const varieties = copyVarieties.map((variety, index) => {
      let data = {
        name: variety.data.name,
        barcode: variety.data.barcode,
        discount: this.getDiscountType(variety.data.discountType) ? variety.data.discount : null,
        discount_type: this.getDiscountType(variety.data.discountType),
        discount_until: variety.data.discountUntil,
        images: this.getImages(variety.data.images),
        price: variety.data.price || this.productPricing.unitPrice,
        weight: variety.data.weight || this.productPricing.unitWeight,
        max_limit: variety.data.max_limit || this.productPricing.uniMaxLimit,
        purchase_price: variety.data.purchasePrice,
        quantity: variety.data.quantity,
        is_head: variety.data.is_head,
        SKU: variety.data.SKU,
        order: 99999 - index,
        gifts: this.getProductGifts(variety.data.productGifts),
        ...this.constructor.getAttributesFromTs(variety.ts),
        ...this.constructor.getAdditionalDataForVariety(variety)
      }
      let vAE = this.varietyAlreadyExists(data)
      if (vAE) {
        data.id = vAE
      }
      return data;
    })

    return varieties
  }

  varietyAlreadyExists(variety) {
    if (!this.edit) {
      return false;
    }
    let colorId = variety.color_id
    let attrs = variety.attributes.map(a => Number.parseInt(a.id));
    for (let v of this.edit.varieties) {
      if (v.color_id != colorId) {
        continue;
      }
      let foundAttrs = v.attributes.map(a => Number.parseInt(a.id))
      if (foundAttrs.length !== attrs.length) {
        continue;
      }
      let ok = true;
      foundAttrs.forEach(att => {
        if (!attrs.includes(att)) {
          ok = false;
        }
      })

      v.attributes.forEach(vAttr => {
        /** @var varietyAttr {id: Number, value: String|Number} */
        variety.attributes.forEach(varietyAttr => {
          if (vAttr.id == varietyAttr.id && vAttr.pivot.attribute_value_id != varietyAttr.value
              && vAttr.pivot.value != varietyAttr.value) {
            ok = false;
          }
        })
      })
      if (ok) {
        return v.id
      }
    }

    return false
  }

  static getAttributesFromTs(ts) {
    let attributes = [];
    let color_id = null;
    ts.forEach((t) => {
      if (t.type === 'color') {
        color_id = t.values[t.value]
      } else {
        attributes.push({
          id: t.model.id,
          value: t.values[t.value].id ? t.values[t.value].id : t.values[t.value]
        })
      }
    })

    return {
      attributes, color_id
    }
  }

  getImages(images) {
    if(typeof images === 'undefined') {
      return []
    }
    return images.map(img => {
      if (!isNaN(img.key)) {
        return img.key; // return id
      }

      return img.url;
    });
  }

  getSpecifications(specifications) {
    // Index hamun id e
    // masalan [undefined, undefined, object]
    // id object 3 vom mishe 2
    let specs = [];
    specifications.forEach((spec,id) => {
      if (typeof spec === 'undefined') {
        return;
      }

      specs.push({
        id: id,
        value: spec
      })
    })
    return specs
  }

  getSizeCharts(sizeCharts) {
    let finalSizeCharts = [];
    sizeCharts.forEach((sizeChart, index) => {
      let isEmpty = true;
      sizeChart.chart.forEach(row => {
        row.forEach(cell => {
          if (cell) {
            isEmpty = false;
          }
        })
      })
      if (isEmpty) {
        return;
      }
      finalSizeCharts.push({
        title: sizeChart.title || ('سایزچارت ' + index),
        chart: sizeChart.chart,
        type_id: sizeChart.type_id
      })
    })
    return finalSizeCharts
  }

  getDiscountType(discountType) {
    if (discountType === 'none') {
      return null;
    }
    return discountType ? discountType : null
  }

  getProductGifts(productGifts) {
    return productGifts.gifts.map(giftId => {
      return {
        id: giftId,
        should_merge: productGifts.shouldMerge
      }
    })
  }


  static getAdditionalDataForVariety(variety) {
    return {}
  }
}
