import '@@/core/assets/css/print.css'
import { Printd } from "printd";
import styles from './getStyles'
import configProvider from "@@/core/configProvider";
import {BTable} from "bootstrap-vue";

export default {
  components: {
    BTable
  },
  props: {
    order: Object,
    settings: Array
  },
  configProvider,
  data() {
    return {
      width: "200px",
      height: "80px",
      mode: "SVG",
      type: "Ean8",
      value: "11223344",
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "product",
          label: "محصول",
        },
        {
          key: "amount",
          label: "قیمت ",
        },
        {
          key: "discount_amount",
          label: "تخفیف",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "total_price",
          label: "مبلغ کل",
        },
        {
          key: "note",
          label: "یادداشت خرید",
        },
      ],
    };
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        if (this.order) {
          this.order.active_items = this.order.items.filter(i => i.status)
        }
      }
    }
  },
  computed: {
    items() {
      let items = [];
      // this.order.
    },
    invoice() {
      let invoice = this.order.invoices.filter(
        (invoice) => invoice.status == "success"
      );
      if (invoice != "") {
        return invoice[0];
      } else {
        return null;
      }
    },
    invoicePayment() {
        let invoicePayment = this.invoice ? this.invoice.payments.filter(
           (payment) => payment.status == "success"
        ) : [];
        if (invoicePayment != '') {
          return invoicePayment[0]
        } else {
          return null
        }
    },
    address() {
      return JSON.parse(this.order.address);
    },
    total() {
      return this.order.active_items.reduce((sum, item) => {
        sum += item.amount * item.quantity;
        return sum;
      }, 0);
    },
    totalPrice() {
      if (this.order.discount_amount) {
        return (
          this.total + this.order.shipping_amount - this.order.discount_amount
        );
      } else {
        return this.total + this.order.shipping_amount;
      }
    },
  },
  methods: {
    orderPrint() {
      const d = new Printd();
      let _styles;
      if (configProvider.get('print_font')) {
        _styles = styles + "@font-face{font-family:'yekan-bakh';src:url(" + configProvider.get('print_font') + ") format('woff');font-weight:normal;font-style:normal;}"
      } else {
        _styles = styles;
      }
      d.print(document.getElementById("print"), [_styles]);
    },
  },
};
